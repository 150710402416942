import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FormEvent, useEffect } from 'react';
import { stripeServer } from '../../api/stripe-router';
import BankLogos from '../../icons/BankLogos';
import Spinner from '../shared/Spinner';

export interface PaymentComponentProps {
  paymentError: string | null;
  setPaymentError: (value: string | null) => void;
  setPaymentSuccess: (value: boolean) => void;
  paymentProcessing?: boolean;
  setPaymentProcessing: (value: boolean) => void;
  getReport: () => Promise<void>;
  setReportUrl?: (url?: string) => void;
  ppsrDisabled?: boolean;
  readTerms: boolean;
  paymentIntentId: string | null;
  setPaymentIntentId: (value: string | null) => void;
}

export default function CardDetails({
  paymentError,
  setPaymentError,
  setPaymentSuccess,
  paymentProcessing,
  setPaymentProcessing,
  getReport,
  setReportUrl,
  readTerms,
  paymentIntentId,
  setPaymentIntentId,
}: PaymentComponentProps) {
  const stripe = useStripe();
  const elements = useElements();

  const handleCheckout = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPaymentProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    try {
      const {
        data: { clientSecret, paymentIntentId },
      } = await stripeServer.post('/caranalysis/payment-intent');

      if (!paymentIntentId) {
        throw new Error('Payment could not be processed');
      }

      setPaymentIntentId(paymentIntentId);

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement) as any,
          },
        }
      );

      if (error) {
        throw new Error(error.message || 'Payment confirmation failed');
      }

      if (paymentIntent.status === 'requires_capture') {
        await getReport();
        await stripeServer.post(
          `/caranalysis/payment-intent/${paymentIntentId}/capture`
        );
        setPaymentSuccess(true);
      } else {
        throw new Error('Payment could not be processed');
      }
    } catch (error: any) {
      setPaymentError(error.message || 'An error occurred during checkout');
      setPaymentProcessing(false);
      setReportUrl?.(undefined);

      if (paymentIntentId) {
        await stripeServer.post(`/caranalysis/payment-intent/cancel`, {
          paymentIntentId,
        });
        setPaymentIntentId(null);
      }
    }
  };

  useEffect(() => {
    if (!elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    cardNumberElement?.on('change', () => {
      setPaymentError(null);
    });

    const cardExpiryElement = elements.getElement(CardExpiryElement);
    cardExpiryElement?.on('change', () => {
      setPaymentError(null);
    });

    const cardCvcElement = elements.getElement(CardCvcElement);
    cardCvcElement?.on('change', () => {
      setPaymentError(null);
    });
  }, [elements]);

  return (
    <div className='flex flex-col space-y-8 my-5'>
      <BankLogos />
      <form onSubmit={handleCheckout} className='space-y-4'>
        <div>
          <label htmlFor='card-number' className='ml-1 text-p1'>
            Card Number
          </label>
          <div className='w-full bg-gray-50 py-2 border border-grey-dark rounded'>
            <CardNumberElement className='ml-1' />
          </div>
        </div>
        <div className='flex flex-row justify-between space-x-4 pb-2'>
          <div className='w-1/2'>
            <label htmlFor='expiry' className='ml-1 text-p1'>
              Expiry Date
            </label>
            <div className='bg-gray-50 py-2 border border-grey-dark rounded'>
              <CardExpiryElement className='ml-1' />
            </div>
          </div>
          <div className='w-1/2'>
            <label htmlFor='cvc' className='ml-1 text-p1'>
              CVC
            </label>
            <div className='bg-gray-50 py-2 border border-grey-dark rounded'>
              <CardCvcElement className='ml-1' />
            </div>
          </div>
        </div>
        <button
          className='bg-green-600 w-full p-3 rounded grid grid-cols-9 sm:grid-cols-3'
          type='submit'
          disabled={paymentProcessing || !readTerms}
        >
          {!paymentProcessing ? (
            <div className='flex justify-end col-span-2 sm:col-span-1 my-auto'>
              <img
                src={require('../../icons/secure-icon.png')}
                alt='secure-outline'
                className='text-white'
              />
            </div>
          ) : (
            <Spinner customStyling='fill-green-600' />
          )}
          <div className='text-grey-pale text-p1 sm:text-p2 col-span-5 sm:col-span-1'>
            Secure Payment
          </div>
        </button>
        {paymentProcessing && (
          <div className='text-p2'>This can take up to one minute.</div>
        )}
      </form>
      {paymentError && (
        <div className='bg-red-200 w-full p-3 rounded flex'>
          <div className='mx-auto text-red-800'>{paymentError}</div>
        </div>
      )}
    </div>
  );
}
