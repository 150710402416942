import { Link } from 'react-router-dom';

interface SearchErrorProps {
  error: string | null;
}

export default function SearchError({ error }: SearchErrorProps) {
  const getErrorMessageElement = () => {
    switch (error) {
      case 'lookup-error':
        return (
          <p className='my-auto text-center text-white text-p2 font-semibold'>
            Sorry, we couldn't find your vehicle. Please check your details and
            try again.
          </p>
        );
      case 'valuation-error':
        return (
          <p className='my-auto text-center text-white text-p2 font-semibold'>
            Sorry, we could not generate your report. Please try again or
            <Link
              to='/contact-us'
              className='text-primary-blue-light hover:text-blue-400'
            >
              &nbsp;contact our team&nbsp;
            </Link>
            for assistance
          </p>
        );
      case 'missing-odometer':
        return (
          <p className='my-auto text-center text-white text-p2 font-semibold'>
            Please enter kms to proceed with report generation
          </p>
        );
      default:
        return (
          <p className='my-auto text-center text-white text-p2 font-semibold'>
            {error}
          </p>
        );
    }
  };

  return (
    <div className='flex h-20 justify-center p-4'>
      {getErrorMessageElement()}
    </div>
  );
}
