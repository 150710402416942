import cx from 'classnames';
import { omit } from 'lodash';
import { FormEvent, MouseEvent, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import {
  VehicleDetails,
  useFacetVehicleSearchLazyQuery,
  useGetValuationLazyQuery,
  useLookupVehicleLazyQuery,
} from '../../graphql/generated';
import FacetsForm from './FacetsForm';
import OdometerSlider from './OdometerSlider';
import RegoForm from './RegoForm';
import SearchError from './SearchError';
import VehicleSearchResults from './VehicleSearchResults';

export type FacetOptions = 'make' | 'model' | 'year' | 'badge';

export interface FacetSearchParams {
  facet: FacetOptions;
  make: string;
  model: string;
  year: string;
  badge: string;
}

export default function VehicleSearchForm() {
  const [regoOrVin, setRegoOrVin] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [odometer, setOdometer] = useState<string>('');
  const [searchError, setSearchError] = useState<string | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const [displayManualSearch, setDisplayManualSearch] =
    useState<boolean>(false);
  const [facetSearchFields, setFacetSearchFields] = useState<FacetSearchParams>(
    {
      facet: 'make',
      make: '',
      model: '',
      year: '',
      badge: '',
    }
  );

  const [searchResults, setSearchResults] = useState<VehicleDetails[]>([]);
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState<number>(0);
  const [vehicleData, setVehicleData] = useState<any>(null);

  const redirect = useNavigate();

  const [lookupVehicle, { loading: vehicleLookupLoading }] =
    useLookupVehicleLazyQuery();

  const [facetVehicleSearch, { loading: facetVehicleSearchLoading }] =
    useFacetVehicleSearchLazyQuery();

  const [getValuation, { loading: valuationLoading }] =
    useGetValuationLazyQuery();

  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const handleRegoOrVinLookup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchError(null);
    setSearchResults([]);

    if (!recaptchaToken) {
      setSearchError('Recaptcha verification failed');
      return;
    }

    const { data, error } = await lookupVehicle({
      variables: {
        rego_or_vin: regoOrVin,
        state,
        recaptcha_token: recaptchaToken,
      },
    });

    if (error) {
      setSearchError('lookup-error');
      return;
    }

    if (data?.lookup_vehicle) {
      setSearchResults(data.lookup_vehicle);
      setVehicleData(data.lookup_vehicle[selectedVehicleIndex]);

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'vehicle-search-success',
          searchParams: {
            rego_or_vin: regoOrVin,
            state,
          },
          vehicleData: data.lookup_vehicle[selectedVehicleIndex],
        });
      }

      return;
    }

    setSearchError('lookup-error');
  };

  const handleGetMyReport = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearchError(null);

    if (!odometer || odometer === '0') {
      setSearchError('missing-odometer');
      return;
    }

    if (!vehicleData?.vehicle_id) {
      return;
    }

    const { vehicle_id, vehicle_title, vin } =
      searchResults[selectedVehicleIndex];

    const isVin = regoOrVin.length === 17;

    const regoOrVinVehiclePayload = {
      vehicle_id,
      rego: isVin ? undefined : regoOrVin,
      vin: isVin ? regoOrVin : vin,
      state,
      odometer,
    };

    const { data: valuationData, error } = await getValuation({
      variables: {
        input: regoOrVinVehiclePayload,
      },
    });

    if (error) {
      setSearchError('valuation-error');
      return;
    }

    if (vehicleData && valuationData?.get_valuation) {
      redirect('/payment', {
        state: omit(
          {
            ...regoOrVinVehiclePayload,
            ...valuationData.get_valuation,
            vehicle_title,
          },
          '__typename'
        ),
      });
    }
  };

  const onRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  return (
    <div>
      <RegoForm
        regoOrVin={regoOrVin}
        setRegoOrVin={setRegoOrVin}
        setRegoOrVinLookupError={setSearchError}
        state={state}
        setState={setState}
        vehicleLoading={vehicleLookupLoading}
        handleSubmit={handleRegoOrVinLookup}
        searchDisabled={!(regoOrVin.length && state.length && recaptchaToken)}
      />
      <div
        className={cx(
          !((regoOrVin && state) || recaptchaToken) && 'hidden',
          'py-2'
        )}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          onChange={onRecaptchaChange}
        />
      </div>
      {displayManualSearch && (
        <div className='flex flex-col space-y-4'>
          <div className='bg-white h-px mt-4' />
          <p className='text-white text-p2 font-semibold mx-auto'>
            Manual Search
          </p>
          <FacetsForm
            facetSearchFields={facetSearchFields}
            setFacetSearchFields={setFacetSearchFields}
            setSearchResults={setSearchResults}
            setSearchError={setSearchError}
          />
        </div>
      )}
      {searchResults.length > 0 && (
        <>
          <div className='my-3'>
            <OdometerSlider
              value={Number(odometer)}
              handleChange={(kms: number) => setOdometer(String(kms))}
            />
          </div>
          <VehicleSearchResults
            setOdometer={setOdometer}
            searchResults={searchResults}
            valuationLoading={valuationLoading}
            handleGetMyReport={handleGetMyReport}
            selectedVehicleIndex={selectedVehicleIndex}
            setSelectedVehicleIndex={setSelectedVehicleIndex}
            vehicleLookupLoading={vehicleLookupLoading}
            facetVehicleSearchLoading={facetVehicleSearchLoading}
          />
        </>
      )}
      {searchError && <SearchError error={searchError} />}
    </div>
  );
}
